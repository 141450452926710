<template>
  
        <ds-detail class='dt' :username="username"></ds-detail>
     
</template>

<script>

import dsDetail  from "./detailModel"
export default {
  name: 'DatasetDetail',
  components: {

    dsDetail,
  },

  data() {
    return {   
      id: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      username:'',
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "数据集和模型",
          path: "/dataset",
          query: {},
        },
        {
          name: "详细信息",
          path: "",
          query: {},
        },
      ],
      nowdata:{},
    }
  },
  
  created() {
    let checkLogin = this.until.checkLogin();
    let currPath = this.$route.path
    console.log(" current route path", this.$route.path)
    console.log(" current route params", this.$route.params)
    console.log(" check login ", checkLogin)
    if(!checkLogin) {
      console.log(" !checkLogin  , jumpt /tologin ", !checkLogin)
      this.until.jump.call(this,'/tologin', "push", {title:"数据集合模型资源库", redirect: currPath});
    } else {
      this.init()
      this.getdata();
    }
  },

  methods: {
    init(){
      // console.log(" user-------", this.until.getUserInfo())
      // console.log(" user-------", this.until.getUserInfo().username)
      // console.log(" user-------", this.until.getUserInfo().id)
      // this.username = this.until.getUserInfo().username;
      // console.log(" this.username ", this.username)
      this.id = this.$route.params.id
      // console.log("   dataset id " ,   this.id)
    },
    getdata() {
      // this.$api.getDatasetDetail(this.id).then((res) => {
      //   console.log("  get data " , res)
      //   this.nowdata = res.data; 
      // });
    },
  },
};
</script>

<style scoped lang="less">
.dt {
    //  border: 2px solid #ee410c;
     padding: 20px;
}

</style>
